import React from 'react'

import logo from '@/images/logo-white.svg'
import logoN from '@/images/logo-white-n.svg'
import certified from '@/images/certified.svg'
import discordBanner from '@/images/discord-banner.jpg'

import Link from '@/components/Link'
import Icon from '@/components/Icon'
import LinkWithIcon from '@/components/LinkWithIcon'
import MailchimpForm from '@/components/MailchimpForm'
import ContactForm from '@/components/ContactForm'

export const Footer: React.FC = () => {
    const date = new Date()

    const scrollToTop = () => {
        window.scrollTo(0, 0)
    }

    return (
        <footer id="footer" className="py-12 md:py-16 xl:py-28 bg-black">
            <div className="container mx-auto px-10">
                <div className="grid grid-cols-1 md:grid-cols-14 gap-10">
                    <div className="col-span-full lg:col-span-7 xl:col-span-6 xl:col-start-2">
                        <div className="bg-[#26282b] rounded-lg">
                            <div
                                className="w-full pt-[20%] sm:pt-[15%] bg-top bg-cover bg-no-repeat"
                                style={{
                                    backgroundImage: `url(${discordBanner})`,
                                }}
                            ></div>
                            <div className="p-5">
                                <h3 className="mb-5 font-bold text-sm sm:text-base text-[#acb1b9] uppercase">
                                    You're invited to join our server
                                </h3>
                                <div className="flex items-center justify-between">
                                    <div className="flex items-center justify-start mr-2 sm:mr-8">
                                        <div className="flex-none flex flex-col items-center justify-center w-10 sm:w-14 h-10 sm:h-14 mr-2 sm:mr-4 rounded-xl sm:rounded-2xl bg-secondary text-white">
                                            <img
                                                src={logoN}
                                                alt="nDreams"
                                                className="w-3.5 sm:w-7 h-auto"
                                            />
                                        </div>

                                        <div className="flex-none flex items-center justify-start">
                                            <div className="mr-1.5 font-bold text-xl text-[#d6d9dd]">
                                                nDreamsVR
                                            </div>
                                            <img
                                                src={certified}
                                                alt="nDreams"
                                                className="w-5 h-auto"
                                            />
                                        </div>
                                    </div>

                                    <a
                                        href="https://discord.com/invite/nDreams"
                                        rel="noopener noreferrer"
                                        target="_blank"
                                        className="flex-none inline-flex items-center justify-center h-10 px-6 bg-[#209b50] hover:bg-opacity-80 font-medium text-white text-center rounded-sm"
                                    >
                                        Join
                                    </a>
                                </div>
                            </div>
                        </div>

                        <div className="flex items-center text-white mt-8 md:mt-12">
                            <a
                                href="https://twitter.com/ndreams"
                                rel="noopener noreferrer"
                                target="_blank"
                                className="hover:text-red transition duration-200 w-6"
                            >
                                <Icon name="twitter" />
                                <span className="hidden">Twitter</span>
                            </a>
                            <a
                                href="https://www.youtube.com/channel/UCI5twGy4kDS1xf141wZP5jg"
                                rel="noopener noreferrer"
                                target="_blank"
                                className="ml-4 lg:ml-6 hover:text-red transition duration-200"
                            >
                                <Icon name="youtube" />
                                <span className="hidden">YouTube</span>
                            </a>
                            <a
                                href="https://www.facebook.com/nDreamsVR/"
                                rel="noopener noreferrer"
                                target="_blank"
                                className="ml-4 lg:ml-6 hover:text-red transition duration-200"
                            >
                                <Icon name="facebook" />
                                <span className="hidden">Facebook</span>
                            </a>
                            <a
                                href="https://www.instagram.com/ndreams_vr/?hl=en"
                                rel="noopener noreferrer"
                                target="_blank"
                                className="ml-4 lg:ml-6 hover:text-red transition duration-200"
                            >
                                <Icon name="instagram" />
                                <span className="hidden">Instagram</span>
                            </a>
                            <a
                                href="https://discord.com/invite/nDreams"
                                rel="noopener noreferrer"
                                target="_blank"
                                className="ml-4 lg:ml-6 hover:text-red transition duration-200 w-6"
                            >
                                <Icon name="discord" />
                                <span className="hidden">Discord</span>
                            </a>
                        </div>
                    </div>
                    <div className="col-span-full lg:col-span-7 xl:col-span-6">
                        <h3
                            className={
                                'line-styled-heading text-white mb-8 md:mb-12 text-4xl md:text-3xl lg:text-4xl xl:text-5xl font-bold uppercase'
                            }
                        >
                            Get in <span className="block">touch</span>
                        </h3>
                        <ContactForm />
                    </div>
                </div>
                <div className="lg:grid lg:grid-cols-1 xl:grid-cols-14">
                    <div className="lg:col-span-14 xl:col-span-12 xl:col-start-2 mt-8 md:mt-12 pt-12 md:pt-16 border-t border-dashed border-white">
                        <div className="lg:grid lg:grid-cols-3 lg:col-span-full xl:col-span-12 xl:col-start-2">
                            <div className="flex flex-col justify-center items-center lg:items-start text-sm text-boulder">
                                <a
                                    href="https://ndreams.com"
                                    rel="noreferrer noopenner"
                                >
                                    <img
                                        src={logo}
                                        alt="nDreams"
                                        className="w-30 block mb-4 lg:mb-6"
                                    />
                                </a>
                                <p>
                                    &copy;{date.getFullYear()} nDreams. All
                                    Rights Reserved
                                </p>
                            </div>
                            <div className="flex flex-col justify-center items-center mt-8 lg:mt-0 text-sm text-boulder text-center">
                                <p>
                                    nDreams Limited, Spectrum Point, 279
                                    Farnborough Road, Farnborough, Hampshire,
                                    GU14 7LS, England, UK
                                </p>
                                <ul className="flex items-center justify-center mt-8 font-bold">
                                    <li className="ml-4 hover:text-red transition duration-200">
                                        {/* <Link
                      to="https://ndreams.com/privacy-policy"
                      external={true}
                    >
                      Privacy Policy
                    </Link> */}
                                        <Link
                                            to="https://ndreams.com/terms"
                                            external={true}
                                        >
                                            Legal
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                            <div className="flex flex-col justify-center items-center lg:items-end mt-8 lg:mt-0">
                                <LinkWithIcon
                                    color="mi-light"
                                    text="Back to top"
                                    icon="arrowUp"
                                    clickHandler={scrollToTop}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}
